<template>
  <div>
    <img class = 'office head' src="../../assets/images/contact/idfl-world.png" />
    <div class='office wrapper' v-for='office in offices' :key="office.id">
      <div class='office bubble' :id='[officeId(office.name)]'></div>
      <h2 class='office name'>{{ office.name }}</h2>
        <div class='office contact' v-for = "contact in office.contact_info" :key='contact.id'>
            <p class='office break'></p>
            <h4>{{ contact.business_segment_name }}</h4>
            <p>{{ contact.phone_number }}</p>
            <p><a :href='["mailto:"+contact.email]'>{{ contact.email }}</a></p>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Contact',
  data() {
    return {
      offices:[]
    }
  },
  methods: {
    fetchOffices(){
    this.loading = true;
      this.$store.dispatch('Contact/fetchOffices')
      .then(data => {
        this.offices = data.data;
      })
      .catch(err => {
        this.$toast.error(err.message);
      })
      .finally(() => {
        this.loading = false;
      });
    },
    officeId( officeName ){
      return officeName.replaceAll(" ", "-")
    }
  },
  created(){
    this.fetchOffices();
  },
};
</script>


<style lang="scss" scoped>
.office.wrapper{
  background-color:rgba(223, 236, 246, 0.4);
  float:left;
  color:rgba(22, 50, 92, 0.8);
  border-radius:5px;
  border: 2px solid #e6f4ff;
  margin:30px;
  padding:30px;
  box-shadow:5px;
  width:250px;
  min-height:300px;
  box-shadow: 0px 0px 14px 2px rgba(0,0,0,0.43);
}
.office.contact p, .office.contact a{
  margin:0px;
  text-align:right;
  text-decoration:none;
  color:rgba(22, 50, 92, 0.8);
}
.office.contact a:hover{
  text-decoration:underline;
}
.office.break{
  width:100%;
  height:1px;
  border:1px solid rgba(22, 50, 92, 0.8);
}
.office.head{
  width:100%;
}
.office.bubble{
  width:20px;
  height:20px;
  border-radius:20px;
  background-color:blue;
  margin-top:-20px;
  margin-left:-20px;
}
/*China*/
#IDFL-China{
  background-color:#ea38eb;
}
/*Europe*/
#IDFL-Europe{
  background-color:#f52663;
}
/*Salt Lake*/
#IDFL-Salt-Lake{
  background-color:#38ea9a;
}
/*Taipei*/
#IDFL-Taipei{
  background-color:#55ea38;
}
/*Vietnam*/
#IDFL-Vietnam{
  background-color:#f2ee1a;
}
/*Japan*/
#IDFL-Japan{
  background-color:#cb2e2e;
}
/*Turkey*/
#IDFL-Turkey{
  background-color:#001dff;
}
/*Pakistan*/
#IDFL-Pakistan{
  background-color:#00dcff;
}
/*India*/
#IDFL-India{
  background-color:#8712df;
}
/*Bangladesh*/
#IDFL-Bangladesh{
  background-color:#df8612;
}

</style>
